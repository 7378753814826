import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/benefits-of-automated-deployment",
  "date": "2014-02-14",
  "title": "BENEFITS OF AUTOMATED DEPLOYMENT",
  "author": "admin",
  "tags": ["development", "deployment"],
  "featuredImage": "feature.jpg",
  "excerpt": "When deploying software manually, the tendancy is to deploy once a month or perhaps once every two or three months. Deployment doesn’t stop for production only, there are test, staging and production as well."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Manual deployment can lead to human errors and consume a huge chunk of man-hours to fix. Adopting automation significantly addresses the issues with eliminating human errors, deployments become faster and not only that, their habits change as well, with the ability to deploy more frequently with many times a week, even daily. The benefits of automated deployment are proven in the chart below.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-02-14-benefits-of-automated-deployment/embeddable_9d6af187-7497-4524-99aa-057158d4ed4b.png",
        "alt": "Automated deployment"
      }}></img></p>
    <h2>{`Why does that matter?`}</h2>
    <p>{`When a bug is fixed or a feature is developed, it is best delivered to the client rather than sitting for the next release in months. The sooner you deliver the new feature is sooner the client gets the values for it, and sooner you get feedback and that improves. A feature or a bug fix waiting for deployment is like inventory waiting for sale that doesn't make money for you.`}</p>
    <p>{`So how does automation increase the frequency of deployment?  Here are the factors:`}</p>
    <ul>
      <li parentName="ul">{`Less `}<strong parentName="li">{`downtime`}</strong>{` due to faster deployment`}</li>
      <li parentName="ul">{`Less amount of `}<strong parentName="li">{`people`}</strong>{` required to execute the deployment process`}</li>
      <li parentName="ul"><strong parentName="li">{`Reliable`}</strong>{` deployment process, due to lack of human errors`}</li>
      <li parentName="ul">{`More `}<strong parentName="li">{`frequent`}</strong>{` deployments due to all above facts`}</li>
      <li parentName="ul">{`Frequent deployments helps out `}<strong parentName="li">{`resolving`}</strong>{` `}<strong parentName="li">{`bugs`}</strong>{` faster`}</li>
    </ul>
    <p><strong parentName="p">{`How does an Automated Deployment Tool can assit you?`}</strong>{`Your team might already be using a source control tool, like TFS, Git, Mercurial or Subversion. Say, you already have a build server or CI (continuous integration) server in place, like JetBrains TeamCity, Team Build or Cruise Control. A tool like Octopus can fit  into your existing software delivery pipeline by building on top of it. With a tool like Octpopus Deploy, the automated deployment pipeline look like this:`}</p>
    <ul>
      <li parentName="ul">{`Developers commit their code changes to source control`}</li>
      <li parentName="ul">{`Your build/CI server compiles your code, runs unit tests runs, BDD tests, and bundles the applications into packages`}</li>
      <li parentName="ul">{`Octopus deploys those packages to your test, staging and production environments`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2014-02-14-benefits-of-automated-deployment/embeddable_f158ab9e-c534-4c7a-af5a-9bfe3570c38c.png",
        "alt": "Upload"
      }}></img></p>
    <p>{`Note that deployments could be triggered in a few different ways. For example:`}</p>
    <ul>
      <li parentName="ul">{`Your build/CI server might trigger a deployment to a test environment as soon as the build finishes`}</li>
      <li parentName="ul">{`You might allow testers and developers to "self service" deploy to pre-production environments`}</li>
      <li parentName="ul">{`Release managers/operations teams can trigger production deployments`}</li>
    </ul>
    <h2>{`Packaging applications`}</h2>
    <p>{`Before an application can deployed, it needs to be turned into a package. Think of a package as being like a ZIP file containing all of the binaries, scripts, CSS files, images and configuration files needed for your application to run. Octopus Deploy uses the NuGet package file format.`}</p>
    <h2>{`Secure deployments`}</h2>
    <p><strong parentName="p">{`Tentacle`}</strong>{`, a lightwieght agent service, will deploy your applications to multiple servers in multiple environments., which you install on each of the machines that you plan to deploy software to. Octopus uses SSL to push packages and deployment instructions to the Tentacles, and the connection uses a two-way trust relationship: similar to SSH in the Linux/UNIX environments.`}</p>
    <h2>{`Managing configuration differences`}</h2>
    <p>{`Different environments, have different configurations when it comes to deployment. This might require different connection strings in test and in production. You might need to set a password for an SMTP server, or an API key for an external API. To support these scenarios, Octopus has the concept of `}<strong parentName="p">{`variables`}</strong>{`. Octopus can even automatically update the configuration file using these variables during deployment, or pass them to powershell scripts for you to perform custom actions on deployment.`}</p>
    <h3>{`Packaging your applications for deployment`}</h3>
    <p>{`Before you can deploy an application using Octopus, you will need to bundle all of the executables, DLL's, configuration files, installation scripts, and anything else the application needs to run into a `}<inlineCode parentName="p">{`package`}</inlineCode>{`.`}</p>
    <h2>{`Creating projects`}</h2>
    <p>{`Once your applications have been packaged, you can create a project to deploy them. A project is a set of deployment steps that you want Octopus to perform, and any related configuration variables. You'll create a project for each group of applications that you plan to deploy together.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-02-14-benefits-of-automated-deployment/embeddable_6f9d2f5b-0457-4ce8-9905-71f108d1894d.png",
        "alt": "Upload"
      }}></img></p>
    <h2>{`Define your deployment process`}</h2>
    <p>{`Your deployment process is like a recipe, specifying all of the steps Octopus Deploy will perform when you deploy your project to an environment.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-02-14-benefits-of-automated-deployment/embeddable_7423d6ba-5463-473b-9e44-389a2a3c9dc9.png",
        "alt": "Upload"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      